<template>
	<div id="body">
		<div v-if="modeflage" id="Modebox">
			<div id="modeboxs">
				<img src="http://dgjapp.oss-cn-shanghai.aliyuncs.com/wxmode.jpg">
			</div>
		</div>
		<div id="APP_header">
			<p>大总管下载中心</p>
		</div>
		<div v-if="wid>720" id="APP_flex">
			<!-- <el-tooltip placement="right" >
				<div slot="content" class="codeimg">
					<img v-if="item.Code" style="width: 200px;" :src="item.Code" >
					<p v-else>点击下载</p>
				</div> -->
			<div v-for="(item,i) in APPlist" id="APP_flex_box" :key='i' @click="downfun(item.url)" @mouseleave="mouseLeaveing(item)" @mouseover='Imgfun(item)'>
				<img :src="item.logo">
				<p>{{ item.name }}</p>
				<span>{{ item.text }}</span>
			</div>
			<!-- </el-tooltip> -->
		</div>
		<div v-else id="APP_flex">
			<div v-for="(item,i) in APPlist" id="APP_flex_box" :key='i' @click="downfun(item.url)">
				<img :src="item.logo">
				<p>{{ item.name }}</p>
				<span>{{ item.text }}</span>
			</div>
		</div>
		<footers></footers>
	</div>
</template>

<script>
import footers from './FOOTER.vue'

export default {
	components: {
		footers
	},
	data() {
		return {
			wid: 0,
			modeflage: false,
			APPlist: [{
				id: 1,
				name: '大管家(安卓)',
				logo: 'https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/down001.png',
				url: 'http://dgjapp.oss-cn-shanghai.aliyuncs.com/jxc.apk',
				text: '进销存安卓手机端APP',
				Code: 'https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/Downjxc.png'
			}, {
				id: 3,
				name: 'OA协同办公(安卓)',
				logo: 'https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/down001.png',
				url: 'http://dgjapp.oss-cn-shanghai.aliyuncs.com/oa.apk',
				text: 'OA办公手机端APP',
				Code: 'https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/downcodeoa.png'
			}, {
				id: 4,
				name: '掌上管家(安卓)',
				logo: 'https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/down001.png',
				url: 'http://dgjapp.oss-cn-shanghai.aliyuncs.com/MY.apk	',
				text: '美业健身系统手机端APP',
				Code: 'https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/DOWNmycode.png'
			}, {
				id: 5,
				name: '掌上报修(安卓)',
				logo: 'https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/down001.png',
				url: 'http://dgjapp.oss-cn-shanghai.aliyuncs.com/BX.apk',
				text: '设备智能报修手机端APP',
				Code: 'https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/bxdowncode.png'
			}, {
				id: 9,
				name: '工程ERP(安卓)',
				logo: 'https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/down001.png',
				url: 'http://dgjapp.oss-cn-shanghai.aliyuncs.com/erp.apk',
				text: '工程ERP手机端APP',
				Code: 'https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/downerp.png'
			}, {
				id: 2,
				name: '融恒智能信息管理平台',
				logo: 'https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/down002.png',
				url: 'http://dgjapp.oss-cn-shanghai.aliyuncs.com/XX.zip',
				text: '融恒智能信息管理平台后台登录'
			}, {
				id: 6,
				name: '数码客显',
				logo: 'https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/down002.png',
				url: 'http://dgjapp.oss-cn-shanghai.aliyuncs.com/smkx.zip',
				text: 'PC客显系统'
			}, {
				id: 7,
				name: '收银前台',
				logo: 'https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/down002.png',
				url: 'http://dgjapp.oss-cn-shanghai.aliyuncs.com/SY.zip',
				text: 'PC前台收银系统'
			}, {
				id: 8,
				name: '打印插件',
				logo: 'https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/down002.png',
				url: 'http://dgjapp.oss-cn-shanghai.aliyuncs.com/Print.rar',
				text: '收银端打印插件'
			}]
		}
	},
	mounted() {
		this.wid = window.innerWidth
		var browser = {
			versions: function () {
				var u = navigator.userAgent,
					 app = navigator.appVersion;
				return { //移动终端浏览器版本信息
					trident: u.indexOf('Trident') > -1, //IE内核
					presto: u.indexOf('Presto') > -1, //opera内核
					webKit: u.indexOf('AppleWebKit') > -1, //苹果、谷歌内核
					gecko: u.indexOf('Gecko') > -1 && u.indexOf('KHTML') == -1, //火狐内核
					mobile: !!u.match(/AppleWebKit.*Mobile.*/), //是否为移动终端
					ios: !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/), //ios终端
					android: u.indexOf('Android') > -1 || u.indexOf('Linux') > -1, //android终端或uc浏览器
					iPhone: u.indexOf('iPhone') > -1, //是否为iPhone或者QQHD浏览器
					iPad: u.indexOf('iPad') > -1, //是否iPad
					webApp: u.indexOf('Safari') == -1 //是否web应该程序，没有头部与底部
				};
			}(),
			language: (navigator.browserLanguage || navigator.language).toLowerCase()
		}
		if (browser.versions.mobile) { //判断是否是移动设备打开。browser代码在下面
			var ua = navigator.userAgent.toLowerCase(); //获取判断用的对象
			if (ua.match(/MicroMessenger/i) == "micromessenger") {
				this.modeflage = true
				// alert('wx')
			}
		}
	},
	methods: {
		downfun(val) {
			window.location.href = val
		},
		Imgfun(val) {
			if (val.Code) {
				val.logo = val.Code
				val.name = '点击或扫码下载'
			}
		},
		mouseLeaveing(val) {
			console.log(val)
			if (val.Code) {
				val.logo = 'https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/down001.png'
				if (val.id == 1) {
					val.name = '大管家(安卓)'
				}
				if (val.id == 3) {
					val.name = 'OA协同办公(安卓)'
				}
				if (val.id == 4) {
					val.name = '掌上管家(安卓)'
				}
				if (val.id == 5) {
					val.name = '掌上报修(安卓)'
				}
				if (val.id == 9) {
					val.name = '工程ERP(安卓)'
				}
			}
		}
	}
}
</script>

<style lang="less" scoped>
@media screen and (min-width: 720px) {
	#APP_header {
		width: 100%;
		height: 100px;
		// background-color: #FF0000;
		text-align: center;
		line-height: 100px;
		font-size: 36px;
	}
	
	#APP_flex {
		width: 80%;
		margin-left: 10%;
		// height: 700px;
		margin-bottom: 30px;
		// background-color: #FF0000;
		display: flex;
		flex-wrap: wrap;
		justify-content: start;
		align-items: center;
		
		.codeimg {
			img {
				width: 40px;
				height: 40px;
			}
		}
		
		#APP_flex_box {
			width: 23%;
			height: 45%;
			margin-bottom: 30px;
			background-color: #F9F9F9;
			margin-left: 1.6%;
			border-radius: 10px;
			box-shadow: 1px 1px 12px #dcdcdc;
			text-align: center;
			cursor: pointer;
			transition: all .8s;
			
			img {
				max-width: 200px;
				height: 200px;
				margin-top: 10%;
				margin-bottom: 10%;
				transition: all .8s;
			}
			
			p {
				font-size: 20px;
				height: 10%;
				font-weight: bold;
			}
			
			span {
				display: block;
				height: 20%;
				width: 80%;
				margin-left: 10%;
				font-size: 14px;
				text-align: center;
			}
		}
		
		#APP_flex_box:hover {
			background-color: #F9F9F9;
			width: 24%;
			height: 46%;
			box-shadow: 1px 1px 12px #d1d1d1;
			
			img {
				max-width: 200px;
				height: 200px;
				margin-top: 10%;
				margin-bottom: 10%;
			}
		}
	}
}

@media screen and (max-width: 720px) {
	#APP_header {
		width: 100%;
		height: 100px;
		// background-color: #FF0000;
		text-align: center;
		line-height: 100px;
		font-size: 28px;
	}
	
	#APP_flex {
		width: 100%;
		height: 800px;
		// background-color: #FF0000;
		display: flex;
		flex-wrap: wrap;
		justify-content: start;
		align-items: center;
		
		#APP_flex_box {
			width: 45%;
			height: 18%;
			background-color: #F9F9F9;
			margin-left: 3.33%;
			border-radius: 10px;
			box-shadow: 1px 1px 12px #dcdcdc;
			text-align: center;
			cursor: pointer;
			
			img {
				max-width: 100%;
				height: 50%;
				margin-top: 5%;
				margin-bottom: 5%;
			}
			
			p {
				height: 10%;
				font-weight: bold;
			}
			
			span {
				display: block;
				height: 20%;
				margin-top: 10%;
				width: 100%;
				font-size: 12px;
				text-align: center;
			}
		}
	}
}

#Modebox {
	width: 100%;
	height: 100%;
	position: fixed;
	top: 0;
	background-color: rgba(0, 0, 0, 0.8);
	z-index: 99999999;
	
	#modeboxs {
		width: 100%;
		height: 100%;
		
		img {
			width: 100%;
		}
	}
}
</style>
